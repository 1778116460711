
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import testimonialImg from '@/assets/imgs/home/testimonial-bg.png';

export default {
  name: 'PageIndex',

  components: {
    FontAwesomeIcon
  },

  async asyncData({ $content }) {
    const results = await Promise.all([
      $content('index').fetch()
    ]);

    const [
      page
    ] = results;

    return {
      page
    };
  },

  data() {
    return {
      testimonialImg
    };
  },

  head() {
    return {
      title: 'Kbrax | Others Write Code We Provide Solutions',
      meta: [
        {
          hid: 'description', property: 'description', name: 'description', content: 'A team of dedicated, knowledgeable and pragmatic innovators delivering solutions to the healthcare, mobility, telecommunication and security industries.'
        },
        {
          hid: 'og:title', property: 'og:title', name: 'og:title', content: 'Kbrax | Others Write Code We Provide Solutions'
        },
        {
          hid: 'og:description', property: 'og:description', name: 'og:description', content: 'A team of dedicated, knowledgeable and pragmatic innovators delivering solutions to the healthcare, mobility, telecommunication and security industries.'
        }
      ]
    };
  },

  computed: {
    testimonialBg() {
      return `background-image: url("${this.testimonialImg}");`;
    }
  }
};
